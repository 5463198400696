<!-- 招生计划查询 -->
<template>
  <div>招生计划查询</div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {}
};
</script>
<style lang='scss' scoped>
</style>